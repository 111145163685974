import {Pipe, PipeTransform} from '@angular/core';

export interface JoinPipeOptions {
    separator?: string;
    toLowerCase?: boolean;
    capitalize?: boolean;
    removeOutputDuplications?: boolean;
    caseSensitive?: boolean;
    capitalizeEach?: boolean;
    pickOneArr?: boolean;
}

const defaultOptions: JoinPipeOptions = {
    separator: ', ',
    toLowerCase: false,
    capitalize: true,
    removeOutputDuplications: false,
    caseSensitive: false,
    capitalizeEach: false,
    pickOneArr: false,
};

@Pipe({name: 'join'})
export class JoinPipe implements PipeTransform {
    transform(input: (string | string[])[], options: JoinPipeOptions = {}): string {
        if (!input?.length) return '';

        const opts = {...defaultOptions, ...options};

        let arr: string[] = opts.pickOneArr && input.length > 1 && input.every(x => Array.isArray(x)) ? <string[]>input[0] : [].concat(...input);
        arr = arr.filter(x => !!x);

        if (!arr.length) return '';

        if (opts.removeOutputDuplications) {
            arr = arr.reduce((acc, curr) => acc.some(x => opts.toLowerCase || opts.caseSensitive ? curr == x : curr.toLowerCase() == x.toLowerCase()) ? acc : [...acc, curr], []);
        }

        let str = arr.map(x => opts.toLowerCase ? x.toLowerCase() : x).map(y => opts.capitalizeEach ? y[0].toUpperCase() + y.slice(1) : y).join(opts.separator);

        if (opts.capitalize) str = str[0].toUpperCase() + str.slice(1);

        return str;
    }
}
